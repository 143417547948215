import React from 'react';

const SalesforceMarketingCloud = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.2514 44.3326H2.34061C2.17914 44.3327 2.02388 44.2716 1.90741 44.1619C1.79093 44.0522 1.72228 43.9026 1.71582 43.7443V2.61281C1.71582 2.45028 1.78169 2.29431 1.89886 2.17938C2.01603 2.06446 2.1749 2 2.34061 2C2.50631 2 2.66518 2.06446 2.78235 2.17938C2.89952 2.29431 2.9654 2.45028 2.9654 2.61281V43.1315H44.2514C44.3422 43.1134 44.4355 43.1154 44.5254 43.1372C44.6153 43.159 44.699 43.2002 44.7707 43.2576C44.8424 43.3151 44.9006 43.3874 44.9403 43.4695C44.98 43.5515 45.0001 43.6412 45.0001 43.732C45.0001 43.8229 44.98 43.9126 44.9403 43.9946C44.9006 44.0767 44.8424 44.1491 44.7707 44.2066C44.699 44.2641 44.6153 44.3051 44.5254 44.3269C44.4355 44.3487 44.3422 44.3507 44.2514 44.3326Z"
      fill="#FCFCFD"
    />
    <path
      d="M4.03977 4.89334C3.95676 4.89686 3.87376 4.88338 3.79632 4.85385C3.71888 4.82431 3.64887 4.77934 3.59009 4.72174L2.34051 3.47159L1.09094 4.72174C0.967226 4.83027 0.80663 4.8902 0.640652 4.8902C0.474673 4.8902 0.314688 4.83027 0.190975 4.72174C0.130507 4.66458 0.0822736 4.59602 0.0494217 4.52021C0.0165699 4.4444 0 4.36291 0 4.28054C0 4.19816 0.0165699 4.11667 0.0494217 4.04086C0.0822736 3.96505 0.130507 3.8965 0.190975 3.83933L1.89023 2.17252C2.01394 2.06399 2.17454 2.00391 2.34051 2.00391C2.50649 2.00391 2.66648 2.06399 2.79019 2.17252L4.48944 3.83933C4.6001 3.96067 4.66151 4.11774 4.66151 4.28054C4.66151 4.44333 4.6001 4.6004 4.48944 4.72174C4.36752 4.83346 4.20662 4.89492 4.03977 4.89334Z"
      fill="#FCFCFD"
    />
    <path
      d="M42.5515 46.0013C42.4725 46.0029 42.394 45.9885 42.3209 45.959C42.2478 45.9294 42.1819 45.8856 42.1269 45.8299C42.0671 45.7748 42.0197 45.7084 41.9872 45.6346C41.9546 45.5608 41.9377 45.4812 41.9377 45.4008C41.9377 45.3204 41.9546 45.2409 41.9872 45.1671C42.0197 45.0933 42.0671 45.0269 42.1269 44.9718L43.3765 43.7462L42.1269 42.4961C42.0367 42.3781 41.993 42.2321 42.0036 42.0849C42.0143 41.9378 42.0785 41.7994 42.1849 41.6951C42.2912 41.5907 42.4326 41.5276 42.5827 41.5172C42.7327 41.5067 42.8815 41.5497 43.0018 41.6382L44.7011 43.305C44.7609 43.36 44.8082 43.4265 44.8408 43.5003C44.8734 43.574 44.8902 43.6536 44.8902 43.734C44.8902 43.8143 44.8734 43.8939 44.8408 43.9676C44.8082 44.0414 44.7609 44.1079 44.7011 44.1629L43.0018 45.8299C42.9431 45.8875 42.8724 45.9324 42.795 45.962C42.7176 45.9915 42.6346 46.0048 42.5515 46.0013Z"
      fill="#FCFCFD"
    />
    <path
      d="M38.3785 7.78639C38.2723 7.78732 38.1675 7.76057 38.0752 7.70889C37.983 7.65721 37.9069 7.58246 37.8538 7.49225L36.9288 6.04596L35.4547 6.9529C35.3097 7.03106 35.1406 7.05429 34.9794 7.01813C34.8181 6.98198 34.6757 6.88908 34.5797 6.75691C34.4965 6.61321 34.4737 6.44331 34.5157 6.28324C34.5576 6.12318 34.6613 5.98538 34.8049 5.89889L36.8043 4.67328C36.9587 4.62453 37.1246 4.62453 37.279 4.67328C37.4377 4.7208 37.5715 4.82603 37.6536 4.96741L38.9032 6.92837C38.9483 6.99527 38.9792 7.07055 38.9935 7.14949C39.0079 7.22843 39.0055 7.30947 38.9868 7.38752C38.9681 7.46557 38.933 7.53903 38.8843 7.60341C38.8356 7.66779 38.7738 7.72168 38.7031 7.76185C38.5998 7.80203 38.4868 7.8106 38.3785 7.78639Z"
      fill="#FCFCFD"
    />
    <path
      d="M12.6873 40.2377H7.689C7.52329 40.2377 7.36442 40.1731 7.24725 40.0582C7.13008 39.9432 7.06421 39.7874 7.06421 39.6249V30.5308C7.06421 30.3682 7.13008 30.2124 7.24725 30.0975C7.36442 29.9826 7.52329 29.918 7.689 29.918H12.6873C12.853 29.918 13.0119 29.9826 13.129 30.0975C13.2462 30.2124 13.3121 30.3682 13.3121 30.5308V39.6249C13.3121 39.7874 13.2462 39.9432 13.129 40.0582C13.0119 40.1731 12.853 40.2377 12.6873 40.2377ZM8.21372 39.0121H12.0875V31.1436H8.21372V39.0121Z"
      fill="#4DE0D1"
    />
    <path
      d="M20.8846 40.2385H15.7613C15.5956 40.2385 15.4367 40.1739 15.3195 40.059C15.2023 39.9441 15.1365 39.7882 15.1365 39.6257V29.0609C15.1429 28.9027 15.2116 28.753 15.3281 28.6434C15.4445 28.5337 15.5998 28.4725 15.7613 28.4727H20.8846C21.0461 28.4725 21.2014 28.5337 21.3179 28.6434C21.4343 28.753 21.503 28.9027 21.5094 29.0609V39.6012C21.5128 39.6837 21.4991 39.766 21.4692 39.8432C21.4393 39.9204 21.3939 39.9909 21.3355 40.0505C21.2772 40.11 21.2074 40.1573 21.1299 40.1896C21.0524 40.2219 20.9688 40.2386 20.8846 40.2385ZM16.3861 39.0129H20.2599V29.6983H16.3861V39.0129Z"
      fill="#4DE0D1"
    />
    <path
      d="M28.9569 40.2384H23.8335C23.6678 40.2384 23.509 40.1738 23.3918 40.0589C23.2746 39.9439 23.2087 39.7881 23.2087 39.6256V25.7027C23.2087 25.5401 23.2746 25.3843 23.3918 25.2694C23.509 25.1545 23.6678 25.0898 23.8335 25.0898H28.9569C29.1226 25.0898 29.2815 25.1545 29.3987 25.2694C29.5158 25.3843 29.5817 25.5401 29.5817 25.7027V39.6256C29.5817 39.7881 29.5158 39.9439 29.3987 40.0589C29.2815 40.1738 29.1226 40.2384 28.9569 40.2384ZM24.4583 39.0128H28.3822V26.3155H24.5083L24.4583 39.0128Z"
      fill="#4DE0D1"
    />
    <path
      d="M37.079 40.2378H31.9556C31.7899 40.2378 31.631 40.1732 31.5139 40.0582C31.3967 39.9433 31.3308 39.7875 31.3308 39.625V20.0152C31.3308 19.8526 31.3967 19.6968 31.5139 19.5819C31.631 19.467 31.7899 19.4023 31.9556 19.4023H37.079C37.2447 19.4023 37.4036 19.467 37.5207 19.5819C37.6379 19.6968 37.7038 19.8526 37.7038 20.0152V39.625C37.7038 39.7875 37.6379 39.9433 37.5207 40.0582C37.4036 40.1732 37.2447 40.2378 37.079 40.2378ZM32.5804 39.0122H36.4542V20.6034H32.5804V39.0122Z"
      fill="#4DE0D1"
    />
    <path
      d="M6.78899 27.8101H6.36372C6.28165 27.807 6.20095 27.7881 6.12637 27.7543C6.0518 27.7206 5.98501 27.6727 5.9293 27.6135C5.87359 27.5543 5.83012 27.4849 5.80178 27.4093C5.77344 27.3337 5.7606 27.2533 5.76395 27.1728C5.76717 27.0923 5.78689 27.0133 5.8213 26.9401C5.85571 26.867 5.90433 26.8012 5.96469 26.7465C6.02504 26.6919 6.09566 26.6494 6.17274 26.6216C6.24983 26.5938 6.33169 26.5812 6.41375 26.5845C6.66367 26.5845 31.1054 27.369 36.4036 5.06282C36.4451 4.90643 36.5457 4.77122 36.6849 4.68476C36.8241 4.59829 36.9916 4.56702 37.1534 4.59708C37.3116 4.63758 37.447 4.73714 37.5311 4.87461C37.6152 5.01208 37.6411 5.17649 37.6031 5.33242C32.4799 26.9768 10.1628 27.8101 6.78899 27.8101Z"
      fill="#FCFCFD"
    />
  </svg>
);

export default SalesforceMarketingCloud;
